




















































































.ventana-exterior {
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100%;
  z-index: 999999;
  left: 0px;
}
@media (min-width: 600px) {
  .formulario {
    text-align: center;
    width: 383px;
    height: 100px;
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    margin: 40px auto 0;
    top: 50%;
    left: 50%;
  }
  .close {
    margin-left: 275px;
  }
}
@media (max-width: 600px) {
  .formulario {
    text-align: center;
    overflow: auto;
    background-color: white;
    width: 300px;
    margin: 40px auto 0;
    padding: 20px 30px;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
    transition: all 0.3s ease;
    margin-bottom: 40px;
    top: 50%;
    left: 50%;
  }
  .close {
    margin-left: 150px;
  }
  .create-portfolio1 {
    margin-top: 10px;
    margin-left: 0px !important;
  }
}
.portfolio-input {
  width: 200px;
  margin: auto;
}
.input-container {
  margin: auto;
  width: fit-content;
}
.button-options {
  text-align: center;
  margin-top: 50px;
}
.create-portfolio1 {
  margin-top: 20px;
  margin-left: 20px;
}

.button-popup {
  width: 160px;
  height: 40px;
  background-color: #00bfff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 0 3px rgba(0, 191, 255, 0.5);
  transition: all 0.2s ease-in-out;
  bottom: 8px;
  right: 0;
  cursor: pointer;
  margin: 12px;
}


.button-container {
  position: relative;
}
